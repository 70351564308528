
<template>
    <div>
        <v-progress-linear :indeterminate="true" v-if="loading"></v-progress-linear>
        <v-container v-else class="my-2">

            <h2>
                {{$store.getters.language.data.driver_bags.driver_bag_id}}: {{driver_bags.driver_bag_id}}
            </h2>
            <h2>
                {{$store.getters.language.data.drivers.driver_name}}: {{driver.driver_name}}
            </h2>
            <h2>
                {{$store.getters.language.data.driver_bags.driver_bag_date}} : {{ new
                Date(driver_bags.driver_bag_date).toISOString().split('T')[0]}} {{ new
                Date(driver_bags.driver_bag_date).toISOString().split('T')[1].split('.')[0]}}
            </h2>
            <h2 v-if="driver_bags.driver_bag_date_delivery!=null">
                {{$store.getters.language.data.driver_bags.driver_bag_date_delivery}} : {{ new
                Date(driver_bags.driver_bag_date_delivery).toISOString().split('T')[0]}} {{ new
                Date(driver_bags.driver_bag_date_delivery).toISOString().split('T')[1].split('.')[0]}}
            </h2>

            <h2>
                {{$store.getters.language.data.driver_bags.driver_bag_status}}: {{driver_bags.driver_bag_status}}
            </h2>

            <h2>
                {{$store.getters.language.data.driver_bags.driver_bag_total}} :
                {{driver_bags.driver_bag_total.toLocaleString()}}
            </h2>
            <h2>
                Total without Delivery : {{driver_bags.driver_bag_final_price.toLocaleString()}}
            </h2>

            <form @submit.prevent="updateDriverBags" autocomplete="off" class="mt-5">
                <v-layout row wrap>
                    <!-- {{selected_rows}} -->
                    <!-- <v-flex xs12 lg2 xl2 md3 sm4>
						<v-select class="mx-1" clearable :items="drivers" v-model="driver_bags.driver_id" dense filled outlined item-text="driver_name" item-value="driver_id" :return-object="false" disabled>
						</v-select>
					</v-flex> -->
                    <!-- <v-flex xs12 lg2 xl2 md3 sm4>
						<v-select class="mx-1" clearable :items="users" v-model="driver_bags.user_id" dense filled outlined item-text="user_username" item-value="user_id" :return-object="false" :label="$store.getters.language.data.users.user_username" disabled>
						</v-select>
					</v-flex> -->
                    <!-- <v-flex xs12 lg2 xl2 md3 sm4>
						<v-text-field v-model="driver_bags.driver_bag_date" type="datetime-local" :label="$store.getters.language.data.driver_bags.driver_bag_date" class="mx-1" filled outlined dense required>
						</v-text-field>
					</v-flex> -->
                    <!-- <v-flex xs12 lg2 xl2 md3 sm4>
						<v-text-field v-model="driver_bags.driver_bag_date_delivery" type="datetime-local" :label="$store.getters.language.data.driver_bags.driver_bag_date_delivery" class="mx-1" filled outlined dense>
						</v-text-field>
					</v-flex> -->
                    <!-- <v-flex xs12 lg2 xl2 md3 sm4>
						<v-select class="mx-1" clearable :items="['out','delivered']" v-model="driver_bags.driver_bag_status" dense filled outlined item-text="driver_bag_status" item-value="driver_bag_status" :return-object="false" :label="$store.getters.language.data.driver_bags.driver_bag_status">
						</v-select>
					</v-flex>

					<v-flex xs12 lg2 xl2 md3 sm4>
						<v-text-field v-model="driver_bags.driver_bag_total" type="text" :label="$store.getters.language.data.driver_bags.driver_bag_total" class="mx-1" filled outlined dense required>
						</v-text-field>
					</v-flex> -->

                    <!-- <v-flex xs12 lg2 xl2 md2 sm4>
						<v-btn color="success" type="submit">{{$store.getters.language.data.driver_bags.update_btn}}</v-btn>
					</v-flex> -->
                </v-layout>
            </form>
            <v-divider class="my-6"></v-divider>
            <v-layout row wrap class="ma-2">
                <v-flex xs12 lg3 xl3 md3 sm4 class="my-4">
                    <h3>The Selected bags are:</h3>
                </v-flex>
                <v-flex xs12 lg2 xl2 md2 sm12 class="my-4">
                    <v-btn color="success" @click="updateDriverBagsOrders('delivered')"
                        v-if="driver_bags.driver_bag_status=='out'">Bags are Delivered</v-btn>
                </v-flex>
                <v-flex xs12 lg2 xl2 md2 sm12 class="my-4">
                    <v-btn color="warning" @click="updateDriverBagsOrders('returned')"
                        v-if="driver_bags.driver_bag_status=='out'">Bags are Returned</v-btn>
                </v-flex>
            </v-layout>
           
            <v-data-table :headers="headers" show-select v-model="selected_rows" :search="search"
                :items="driver_bag_orders" class="elevation-0" item-key="driver_bag_order_id">
                <!-- <template v-slot:[`item.driver_bag_order_id`]="{ item }">
					<div>
						<v-btn icon :to="'/driver_bag_orders-list/'+item.driver_bag_order_id" color="teal" class="mx-1">
							<v-icon> mdi-pencil-outline </v-icon>
						</v-btn>
						<v-btn color="error" icon class="mx-1" @click="selectDriverBagOrders(item)">
							<v-icon> mdi-delete-outline </v-icon>
						</v-btn>
					</div>
				</template> -->
            </v-data-table>

        </v-container>
        <v-snackbar v-model="snackbar.value" :color="snackbar.color">
            {{snackbar.text}}
        </v-snackbar>
    </div>
</template>
<script>
	import requests from './../../requests/driver_bags.request.js'
	import ordersRequests from './../../requests/driver_bag_orders.request.js'
	export default {
		data() {
			return {
				driver_bags: {},
				driver: {},
				driver_bag_orders: [],
				selected_rows: [],
				id: '',
				loading: true,
				snackbar: {
					value: false,
					text: '',
					color: ''
				},
				headers: [


					// {
					// 	text: this.$store.getters.language.data.driver_bags.driver_bag_id,
					// 	align: 'start',
					// 	sortable: true,
					// 	value: 'driver_bag_id',
					// },
					{
						text: this.$store.getters.language.data.packed_orders.packed_order_id,
						align: 'start',
						sortable: true,
						value: 'packed_order_id',
					},
					{
						text: 'Bag Price',
						align: 'start',
						sortable: true,
						value: 'driver_bag_order_total_dinar',
					},
					{
						text: 'Bag Status',
						align: 'start',
						sortable: true,
						value: 'driver_bag_order_status',
					},
					{
						text: this.$store.getters.language.data.customers.customer_username,
						align: 'start',
						sortable: true,
						value: 'customer_username',
					},
					{
						text: this.$store.getters.language.data.customers.customer_name,
						align: 'start',
						sortable: true,
						value: 'customer_name',
					},
					{
						text: this.$store.getters.language.data.customers.customer_phone,
						align: 'start',
						sortable: true,
						value: 'customer_phone',
					},
					{
						text: this.$store.getters.language.data.customers.customer_address,
						align: 'start',
						sortable: true,
						value: 'customer_address',
					},

					{
						text: this.$store.getters.language.data.customers.customer_city,
						align: 'start',
						sortable: true,
						value: 'customer_city',
					},


					{
						text: this.$store.getters.language.data.users.user_username,
						align: 'start',
						sortable: true,
						value: 'user_username',
					}, 
                    // {
					// 	text: 'id',
					// 	align: 'start',
					// 	sortable: true,
					// 	value: 'driver_bag_order_id',
					// }
				],
			}
		},
		computed: {

			drivers() {
				return this.$store.getters.drivers_list
			},

			users() {
				return this.$store.getters.users_list
			},

		},
		mounted() {
			this.id = this.$route.params.id
			this.getOneDriverBags()
			this.getDriverBagOrders()
		},
		methods: {
			getOneDriverBags() {
				this.loading = true
				requests.getOneDriverBags(this.id).then(r => {
					if (r.status == 200) {
						this.driver_bags = r.data.row
						// get the driver bag by searching in all drivers and find the driver with the same id
						this.driver = this.drivers.find(driver => driver.driver_id == this.driver_bags.driver_id)

						this.loading = false
					} else {
						this.snackbar = {
							value: true,
							text: 'Fail to read DriverBags',
							color: 'error'
						}
					}
				})
					.catch(e => {
						this.snackbar = {
							value: true,
							text: 'Fail to read DriverBags',
							color: 'error'
						}
					})
					.finally(() => {
						this.loading = false
					})
			},
			getDriverBagOrders() {
				this.loading = true
				ordersRequests.getDriverBagOrdersByColumn('driver_bag_id', this.id).then(r => {
					if (r.status == 200) {
						this.driver_bag_orders = r.data.rows
                        if(this.driver_bags.driver_bag_status == 'out'){
                            if (this.driver_bag_orders.filter(order => order.driver_bag_order_status == 'out').length == 0) {
					this.updateDriverBags()
				}
                        }
						this.loading = false
					} else {
						this.snackbar = {
							value: true,
							text: 'Fail to read DriverBagOrders',
							color: 'error'
						}
					}
				})
					.catch(e => {
						this.snackbar = {
							value: true,
							text: 'Fail to read DriverBagOrders',
							color: 'error'
						}
					})
					.finally(() => {
						this.loading = false
					})
			},

			updateDriverBagsOrders(status) {
				this.loading = true

				const list = this.selected_rows.map(row => row.driver_bag_order_id)
                console.log(list)
				ordersRequests.updateDriverBagOrdersByIDsListStatus(list, status).then(r => {
					if (r.status == 200) {
						this.snackbar = {
							value: true,
							text: 'DriverBagOrders Updated',
							color: 'success'
						}
						// check if none of the orders are out then change the driver bag status to delivered

						this.getDriverBagOrders()
						this.loading = false
					} else {
						console.log('====================================');
						console.log(r.data)

						this.snackbar = {
							value: true,
							text: 'Update Faild',
							color: 'error'
						}
						this.loading = false
					}
				})
					.catch(e => {
						this.snackbar = {
							value: true,
							text: 'Update Faild',
							color: 'error'
						}
						this.loading = false
					})
					.finally(() => {
						this.loading = false
					})
			},
			
			updateDriverBags() {
				this.loading = true
				delete this.driver_bags.driver_bag_date
				this.driver_bags.driver_bag_status = 'delivered'
				this.driver_bags.driver_bag_date_delivery = new Date().toISOString().split('.')[0].replace('T', ' ')
				// total minus the driver_bag_orders.driver_bag_order_status == 'returned'
                this.driver_bags.driver_bag_total = this.driver_bag_orders.filter(order => order.driver_bag_order_status == 'delivered').reduce((a, b) => a + b.driver_bag_order_total_dinar, 0)
				this.driver_bags.user_id = this.$store.getters.user.user_id
				requests.updateDriverBags(this.id, this.driver_bags).then(r => {
					if (r.status == 200) {
						this.snackbar = {
							value: true,
							text: 'DriverBags Updated',
							color: 'success'
						}
						this.loading = false
						// refresh the page
						window.location.reload()

					} else {
						this.snackbar = {
							value: true,
							text: 'Update Faild',
							color: 'error'
						}
						this.loading = false
					}
				})
					.catch(e => {
						this.snackbar = {
							value: true,
							text: 'Update Faild',
							color: 'error'
						}
						this.loading = false
					})
					.finally(() => {
						this.loading = false
					})
			},
		},
	}
</script>
                    